import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { ArrowCircleRightIcon } from "@heroicons/react/outline"

const Web = ({ data }) => (
<Layout>
  <div className="container">
    <div className="main">
    <div id="web">
    <h2>Around the Web<span className="text-red-400">.</span> <span className="h3">| インタビュー記事等</span></h2>
    <div className="grid md:grid-cols-2 gap-4">
      {data.allAirtable.nodes.map(node => (
        <a href={node.data.url} rel="noopener noreferrer" target="_blank" key={node.recordId}>
        <div className="card">
        <div className="title"><h3>{node.data.title}<span className="text-red-400 ml-1"><ArrowCircleRightIcon className="h-6 w-6 inline lg:hidden" aria-label="詳細" /></span></h3></div>
        <p>
          <span className="tag">{node.data.tag}</span>
          <span className="date">{node.data.date} | {node.data.domain}</span>
        </p>
        </div>
        </a>
      ))}
    </div>
    </div>
    </div>
  </div>
</Layout>
)
export default Web;

export const query = graphql`
{
  allAirtable(
    filter: {data: {category: {eq: "web"}, Publish: {eq: true}}}
    sort: {order: DESC, fields: data___date}
    skip: 6
  ) {
    nodes {
      data {
        title
        url
        date(formatString: "YYYY.M.D")
        domain
        tag
      }
      recordId
    }
  }
  
}  
`;